<template>
  <div>
    <div v-html="context"></div>
  </div>
</template>

<script>
export default {
  name: "TheContent",
  props: {
    context: {
      type: String,
      default: () => {}
    }
  },
  created() {
    //eslint-disable-next-line
    var rxSelf1 = new RegExp('<script+.*>+.*<\/script>');
    if(this.context.match(rxSelf1)){
      console.log('FIND');
      var find = this.context.match(rxSelf1)[0];
      var regex = /<script.*?src="(.*?)"/;
      if(regex.exec(find)){
        var src = regex.exec(find)[1];

        const embed = document.createElement('script');
        embed.classList.add('embeded_javscript');
        embed.src = src;
        document.body.appendChild(embed);
      }
    }
  }
}
</script>

<style scoped>

</style>