import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);
import App from './App.vue'
import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);
import Clipboard from 'v-clipboard'
Vue.use(Clipboard)



Vue.config.productionTip = false

import axios from 'axios'
Vue.prototype.$http = axios

import moment from 'moment'
moment.locale('de');
Vue.prototype.moment = moment



new Vue({
  render: h => h(App),
}).$mount('#t2stream')
