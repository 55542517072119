<template>
  <div id="t2stream">
    <div class="t2_stream__nav">
      <div class="t2_stream____current" tabindex="1" style="display: none;">
        <div class="t2_stream____value">
          <input class="t2_stream____input" type="radio" id="0" value="0" name="sort" checked="checked" v-model="picked"/>
          <p class="t2_stream____input-text">Alle Beiträge</p>
        </div>
        <div class="t2_stream____value">
          <input class="t2_stream____input" type="radio" id="1" value="304" name="sort" checked="checked" v-model="picked"/>
          <p class="t2_stream____input-text">Journalismus</p>
        </div>
        <div class="t2_stream____value">
          <input class="t2_stream____input" type="radio" id="2" value="286" name="sort" checked="checked" v-model="picked"/>
          <p class="t2_stream____input-text">PR</p>
        </div>
        <div class="t2_stream____value">
          <input class="t2_stream____input" type="radio" id="3" value="936" name="sort" checked="checked" v-model="picked"/>
          <p class="t2_stream____input-text">Marketing</p>
        </div>
        <div class="t2_stream____value">
          <input class="t2_stream____input" type="radio" id="4" value="1306" name="sort" checked="checked" v-model="picked"/>
          <p class="t2_stream____input-text">Wirtschaft</p>
        </div>
        <div class="t2_stream____value">
          <input class="t2_stream____input" type="radio" id="5" value="689" name="sort" checked="checked" v-model="picked"/>
          <p class="t2_stream____input-text">Politik</p>
        </div>

        <div class="t2_stream____icon" aria-hidden="true">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"><path d="M500 775.4L10 224.6h980L500 775.4z"/></svg>
        </div>
      </div>
      <ul class="t2_stream____list" style="display: none;">
        <li>
          <label class="t2_stream____option" for="0" aria-hidden="aria-hidden">Alle Beiträge</label>
        </li>
        <li>
          <label class="t2_stream____option" for="1" aria-hidden="aria-hidden">Journalismus</label>
        </li>
        <li>
          <label class="t2_stream____option" for="2" aria-hidden="aria-hidden">PR</label>
        </li>
        <li>
          <label class="t2_stream____option" for="3" aria-hidden="aria-hidden">Marketing</label>
        </li>
        <li>
          <label class="t2_stream____option" for="4" aria-hidden="aria-hidden">Wirtschaft</label>
        </li>
        <li>
          <label class="t2_stream____option" for="5" aria-hidden="aria-hidden">Politik</label>
        </li>
      </ul>
      <a href="/filter" class="t2_stream__filter">Filtern</a>
      <a href="/newsletter/" class="t2_stream__newsletter"><span class="t2_stream__newsletter--icon">Newsletter abonnieren</span></a>
      <a href="/inspiration/" class="t2_stream__newsletter">Inspiration</a>
      <a href="/debatte/" class="t2_stream__newsletter">Debatte</a>
    </div>


    <ul class="t2_stream" v-if="loading">
      <li :key="newsItem.ID" v-for="(newsItem, index) in newsItems" class="t2_stream__item">

        <div v-if="index === 2">
          <div v-html="newsletterBanner" class="t2_newsletter--stream"></div>
        </div>

        <div v-if="index  % 4 === 0 && index > 0 && index <= number && advertisingItems[index]" class="t2_stream__line adv">
          <div href="" class="t2_stream__link t2_ad">
            <!--h2 v-html="advertisingItems[index].title" class="t2_stream__head t2_ad"></h2-->
            <div class="adv-interrupter">
              <a :href="advertisingItems[index].url" style="color: #000">
                -
              </a>
              <a href="http://www.turi2.de/digital" target="_blank" rel="noopener noreferrer">Anzeige</a>
              -
            </div>
            <!--div v-html="advertisingItems[index].post_excerpt" class="t2_stream__content t2_ad"></div-->
            <the-content :context="advertisingItems[index].post_excerpt" class="t2_stream__content t2_ad"></the-content>
          </div>
        </div>

        <div v-if="index <= number" class="t2_stream__line" :class="newsItem.type">
          <a :href="newsItem.url" class="t2_stream__link" v-if="newsItem.title">
            <h2 v-html="newsItem.title" class="t2_stream__head"></h2>
          </a>

          <div class="t2_stream__date">
            {{publishedBefore(newsItem.date)}}
          </div>

          <the-content :context="newsItem.post_excerpt" class="t2_stream__content"></the-content>
          <!--div v-html="newsItem.post_excerpt" class="t2_stream__content"></div-->

          <div class="t2_stream__footer">
            <ul class="t2_stream__taglist">
              <li :key="tag.ID" v-for="tag in newsItem.term" class="t2_stream__tag">
                <a :href="tag.slug" v-html="'#' + tag.name" />
              </li>
            </ul>

            <div class="sharingicons">
              <span class="sharingicons__title">Teilen auf:</span>
              <template>
                <ShareNetwork
                    v-for="network in networks"
                    :network="network.network"
                    :key="network.network"
                    :url="'https://www.turi2.de' + newsItem.url"
                    :title="newsItem.title"
                    :description="newsItem.serp"
                    :quote="newsItem.serp"
                    :twitterUser="sharing.twitterUser"
                >
                  <i class="icon" :class="network.name"></i>
                </ShareNetwork>
              </template>
            </div>

          </div>


        </div>
      </li>
    </ul>
    <ul class="t2_stream" v-if="!loading">
      <li class="t2_stream__item">
        <a class="t2_stream__link">
          <span class="t2_stream__head loads">Inhalt wird geladen...</span>
          <div class="t2_stream__image loads"></div>
          <span class="t2_stream__content loads"></span>
        </a>
      </li>
      <li class="t2_stream__item">
        <a class="t2_stream__link">
          <span class="t2_stream__head loads">Inhalt wird geladen...</span>
          <div class="t2_stream__image loads"></div>
          <span class="t2_stream__content loads"></span>
        </a>
      </li>
      <li class="t2_stream__item">
        <a class="t2_stream__link">
          <span class="t2_stream__head loads">Inhalt wird geladen...</span>
          <div class="t2_stream__image loads"></div>
          <span class="t2_stream__content loads"></span>
        </a>
      </li>
    </ul>
    <div class="t2_stream__button" id="js--showmore" @click="showmore" v-if="showButton">mehr Beiträge</div>
  </div>
</template>

<script>
import TheContent from "@/components/content";
export default {
  name: 'App',
  components: {
    TheContent
  },
  data() {
    return {
      loading: false,
      number: 20,
      itemsLength: 0,
      newsItems: [],
      advertisingItems: [],
      showButton: true,
      newsletterBanner: '',
      items: [],
      picked: 0,
      sharing: {
        url: 'https://www.turi2.de/index/koepfe/',
        title: 'turi2 - der club',
        description: '',
        quote: '',
        hashtags: '',
        twitterUser: 'turi2',
        clipboard: true
      },
      networks: [
        { network: 'email', name: 'Email', class: 'genericon genericon-mail', color: '#333333' },
        { network: 'facebook', name: 'Facebook', class: 'genericon genericon-facebook', color: '#1877f2' },
        { network: 'whatsapp', name: 'Whatsapp', class: 'genericon genericon-whatsapp', color: '#25d366' },
        { network: 'LinkedIn', name: 'LinkedIn', class: 'genericon genericon-linkedin', color: '#25d366' },
        { network: 'twitter', name: 'Twitter', class: 'genericon genericon-twitter', color: '#1da1f2' }
      ]
    }
  },
  watch: {
    picked: function (newVal) {
      const picking = parseInt(newVal);
      this.showButton = true;
      this.number = 20;
      this.filterItems(picking);
    }
  },
  computed: {
  },
  created() {

    if(window._turi2 && window._turi2.user && window._turi2.user.showNewsletterBanner){
      console.error('T2NL-LOAD');
      var xmlhttp = new XMLHttpRequest();
      var me = this;
      xmlhttp.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
          me.newsletterBanner = this.responseText;
        }
      };
      xmlhttp.open('GET', '/koepfe_cache/newsletter_banner.html');
      xmlhttp.send();
    }

    function makeid(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }

    this.url = 'https://www.turi2.de/stuff/json/'+ makeid(5) + '.json';
    if (location.hostname === "localhost" || location.hostname === "127.0.0.1"){
      this.url = 'https://www.turi2.de/stuff/json/last_news.php';
    }

    this.$http.get(this.url)
        .then((result) => {
              this.items = result.data.text
              this.itemsLength = result.data.text.length
              this.newsItems = result.data.text
              this.advertisingItems = result.data.adv
              this.loading = true
        })
  },
  methods: {
    publishedBefore: function (time) {
      const seconds = this.moment().diff(time, 'seconds');
      const minutes = this.moment().diff(time, 'minutes');
      const hours = this.moment().diff(time, 'hours');

      var text = '';

      if(seconds < 60){
        text = 'Vor wenigen Sekunden';
      }else if(seconds > 59 && minutes < 2){
        text = 'Vor einer Minute';
      }else if(minutes >= 2 && minutes < 45){
        text = 'Vor '+minutes+' Minuten';
      }else if(minutes >= 45 && minutes < 90){
        text = 'Vor einer Stunde';
      }else if(minutes >= 90 && hours < 9){
        const calculatedHours = Math.round(minutes / 60);
        text = 'Vor '+calculatedHours+' Stunden';
      }else{
        text = this.moment(time).format('DD.MM.YY - HH:mm') + ' Uhr'

        //text = time
      }

      return text;
    },
    onMouseEnterBusStop: function(ev) {
      console.log(ev);
      const scrolTo = ev.pageY;
      window.scrollTo(0, scrolTo);
    },
    showmore(){
      if(this.number <= this.itemsLength){
        this.number = this.number + 50;
        setTimeout(function(){
          window.scroll(0, window.pageYOffset + 200);
        }, 200);
        if(this.number >= this.itemsLength){
          this.showButton = false;
        }
      }
    },
    filterItems(picking) {
      if(picking === 0){
        this.newsItems = this.items;
        return false;
      }
      this.newsItems = [];
      const allItems = this.items;
      let self = this;
      Object.keys(this.items).map(function(key) {
        let thisTags = allItems[key].tags;
        let thisItems = allItems[key];
        for (key in thisTags) {
          if(parseInt(thisTags[key]) === picking){
            self.newsItems.push(thisItems)
          }
        }
      })
    }
  },
}
</script>

<style>
#t2stream {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 20px 20px 100px 20px;
}
@media screen and (min-width: 600px) {
  #t2stream{
    padding: 20px 0px 0px 0px;
  }
}
.t2_stream{
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
}
.t2_stream__item{
  display: block;
  float: left;
  width: 100%;
}
.t2_stream__link{
  color: #000;
  text-decoration: none;
  display: block;
  width: 100%;
  margin: 10px 0;
  float: left;
}

.t2_stream__item:first-child .t2_stream__line{
  border-top: none;
  padding: 20px 0;
}
.t2_stream__item .t2_stream__line{
  border-top: 1px solid #a6a6a6;
  padding: 20px 0;
  float: left;
  width: 100%;
}

.t2_stream__line.adv:before{
  /*content: '- Anzeige -';
  color: red;*/
}

.t2_stream__link a{
  color: red;
  text-decoration: none;
}
.t2_stream__head{
  display: block;
  text-rendering: optimizeLegibility;
  font-family: Inter,Helvetica,Arial,sans-serif;
  font-weight: 800!important;
  line-height: 1.3;
  letter-spacing: -.02em;
  vertical-align: baseline;
  color: #000;
  font-size: 1.5rem;
  margin-bottom: 0px;
  margin-top: 0;
}
.t2_stream__head.loads{
  margin-bottom: 10px;
  width: calc(100% - 10px);
}
.t2_stream__content{
  display: block;
}
.t2_stream__date{
  font-size: 13px;
  margin: 0 0 10px 0;
  opacity: 0.8;
  width: 100%;
  float: left;
}

img.size-large{
  width: 100%;
  height: auto;
}

.t2_stream__content.loads{
  width: calc(100% - 120px);
  vertical-align: top;
  height: 100px;
  display: inline-block;
}
.t2_stream__content,
.t2_stream__content p{
  margin: 0 0 1rem;
  font-weight: 600!important;
  line-height: 1.314285714;
  font-size: 17px!important;
  text-rendering: optimizeLegibility;
  color: #434343;
  font-family: Inter,Helvetica,Arial,sans-serif;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

.t2_stream__image{
  width: 100px;
  height: 100px;
  margin: 0 10px 10px 0;
  display: inline-block;
}

.t2_stream__content a{
  color: #FF0000;
  font-weight: bold;
  text-decoration: none;
}
.t2_stream__content img{
  /*float: left;*/
  margin: 0 20px 20px 0;
  padding-top: 4px;
}

.t2_stream__content img.alignright{
  float: right;
  margin: 0 0px 20px 20px;
}

.t2_stream__content iframe{
  max-width: 100%;
}
.t2_stream__button{
  background: #FF0000;
  color: #FFF;
  text-align: center;
  padding: 10px 20px;
  margin: 20px 0;
  display: inline-block;
  font-weight: bold;
  cursor: pointer;
  border-radius: 3px;
}
.loads {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeload;
  animation-name: placeload;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #eeeeee;
  display: inline-block;
  color: transparent !important;
  background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(8%, #eeeeee),
      color-stop(18%, #dddddd),
      color-stop(33%, #eeeeee)
  );
  background: -webkit-linear-gradient(
      left,
      #eeeeee 8%,
      #dddddd 18%,
      #eeeeee 33%
  );
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  -webkit-background-size: 800px 104px;
  background-size: 1200px 104px;
  position: relative;
}
.loads p{
  color: transparent !important;
}

.content-shape{
  height: 15px;
}

@-webkit-keyframes placeload{
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes placeload {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.sharingicons {
  display: block;
  vertical-align: middle;
  font-weight: bold;
  width: 100%;
  text-align: left;
  margin: 20px 0px;
  float: left;
  filter: grayscale(1) brightness(0.5);
}


.sharingicons .icon{
  width: 24px;
  height: 24px;
  display: inline-block;
  cursor: pointer;
  margin: 0 10px;
  opacity: 0.6;

}

.sharingicons .LinkedIn{
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M20.4 20.5h-3.6v-5.6c0-1.3 0-3-1.9-3s-2.1 1.4-2.1 2.9v5.7H9.4V9h3.4v1.6c.5-.9 1.6-1.9 3.4-1.9 3.6 0 4.3 2.4 4.3 5.5l-.1 6.3zM5.3 7.4c-1.1 0-2.1-.9-2.1-2.1 0-1.1.9-2.1 2.1-2.1 1.1 0 2.1.9 2.1 2.1 0 1.2-.9 2.1-2.1 2.1zm1.8 13.1H3.6V9h3.6v11.5zM22.2 0H1.8C.8 0 0 .8 0 1.7v20.5c0 1 .8 1.7 1.8 1.7h20.5c1 0 1.8-.8 1.8-1.7V1.7C24 .8 23.2 0 22.2 0z' fill='%232867b2'/%3E%3C/svg%3E");
}
.sharingicons .Whatsapp{
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M.1 24l1.7-6.2C.8 16 .2 14 .2 11.9.2 5.3 5.5 0 12 0c3.2 0 6.2 1.2 8.4 3.5 2.2 2.2 3.5 5.2 3.5 8.4 0 6.6-5.3 11.9-11.9 11.9-2 0-4-.5-5.7-1.4C6.4 22.3.1 24 .1 24zm6.6-3.8c1.7 1 3.3 1.6 5.4 1.6 5.4 0 9.9-4.4 9.9-9.9S17.6 2 12.1 2s-9.9 4.4-9.9 9.9c0 2.2.7 3.9 1.7 5.6l-1 3.6c0 .1 3.8-.9 3.8-.9zM18 14.7c-.1-.1-.3-.2-.6-.3-.3-.1-1.8-.9-2-1-.3-.1-.5-.1-.7.1-.2.3-.8 1-.9 1.2s-.3.2-.6.1-1.3-.5-2.4-1.5c-.9-.8-1.5-1.8-1.7-2.1-.2-.3 0-.5.1-.6.1-.1.3-.3.4-.5.3-.1.3-.3.4-.5.1-.2.1-.4 0-.5-.1-.1-.7-1.6-.9-2.2-.3-.6-.5-.5-.7-.5h-.6c-.2 0-.5.1-.8.4s-1 1-1 2.5 1.1 2.9 1.2 3.1c.1.2 2.1 3.2 5.1 4.5.7.3 1.3.5 1.7.6.7.2 1.4.2 1.9.1.6-.1 1.8-.7 2-1.4.2-.8.2-1.3.1-1.5z' fill='%2325d366'/%3E%3C/svg%3E");
}
@media screen and (min-width: 600px) {
  .sharingicons .Whatsapp{
    display: none !important;
  }
}
.sharingicons .Facebook{
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M24 12c0-6.6-5.4-12-12-12S0 5.4 0 12c0 6 4.4 11 10.1 11.9v-8.4h-3V12h3V9.4c0-3 1.8-4.7 4.5-4.7 1.3 0 2.7.2 2.7.2v3h-1.5c-1.5 0-2 .9-2 1.9V12h3.3l-.5 3.5h-2.8v8.4C19.6 23 24 18 24 12z' fill='%234267b2'/%3E%3C/svg%3E");
}
.sharingicons .Twitter{
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M24 4.6c-.9.4-1.8.7-2.8.8 1-.6 1.8-1.6 2.2-2.7-1 .6-2 1-3.1 1.2-.9-1-2.2-1.6-3.6-1.6-2.7 0-4.9 2.2-4.9 4.9 0 .4 0 .8.1 1.1-4.2-.2-7.8-2.2-10.3-5.1-.4.7-.6 1.5-.6 2.4 0 1.7.9 3.2 2.2 4.1-.8 0-1.6-.2-2.2-.6v.1c0 2.4 1.7 4.4 3.9 4.8-.4.1-.8.2-1.3.2-.3 0-.6 0-.9-.1.6 2 2.4 3.4 4.6 3.4-1.7 1.3-3.8 2.1-6.1 2.1-.4 0-.8 0-1.2-.1 2.2 1.4 4.8 2.2 7.6 2.2 9.1 0 14-7.5 14-14v-.6c.9-.7 1.7-1.5 2.4-2.5z' fill='%231da1f2'/%3E%3C/svg%3E");
}
.sharingicons .Email{
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 230.17 230.17'%3E%3Cpath d='M230 49.585c0-.263.181-.519.169-.779l-70.24 67.68 70.156 65.518c.041-.468-.085-.94-.085-1.418V49.585zM149.207 126.901l-28.674 27.588a7.48 7.48 0 01-5.2 2.096 7.478 7.478 0 01-5.113-2.013l-28.596-26.647-70.614 68.064c1.717.617 3.56 1.096 5.49 1.096h197.667c2.866 0 5.554-.873 7.891-2.175l-72.851-68.009z'/%3E%3Cpath d='M115.251 138.757L222.447 35.496c-2.427-1.443-5.252-2.411-8.28-2.411H16.5c-3.943 0-7.556 1.531-10.37 3.866l109.121 101.806zM0 52.1v128.484c0 1.475.339 2.897.707 4.256l69.738-67.156L0 52.1z'/%3E%3C/svg%3E");
  opacity: 0.4;
  margin: 0 10px 0 0;
}

.sharingicons .copy{
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z'/%3E%3C/svg%3E");
}

.sharingicons a{
  display: inline-block;
  vertical-align: middle;
}
.sharingInfo{
  position: absolute;
  left: 0;
  bottom: 0;
  background: #FF0000;
  color: #FFF;
  padding: 10px;
  font-size: 0.8rem;
}




.t2_stream__nav {
  position: relative;
  display: block;
  width: 100%;
  font-size: 18px;
  color: #60666d;
  text-align: left;
}
/*
.t2_stream____current {
  position: relative;
  box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  outline: none;
  text-align: left;
  width: 100%;
  max-width: 180px;
}
*/

.t2_stream____current {
  position: relative;
  cursor: pointer;
  outline: none;
  text-align: left;
  max-width: 180px;
  display: inline-block;
}
.t2_stream____current:focus + .t2_stream____list {
  opacity: 1;
  -webkit-animation-name: none;
  animation-name: none;
  text-align: left;
}
.t2_stream____current:focus + .t2_stream____list .t2_stream____option {
  cursor: pointer;
  text-align: left;
}
.t2_stream____current:focus .t2_stream____icon svg {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}
.t2_stream____icon {
  position: absolute;
  top: 50%;
  left: 15px;
  width: 20px;
  transition: 0.2s ease;
}
/*
.t2_stream____icon svg{
  fill: #FFF;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
*/
.t2_stream____icon svg{
  display: none;
}
.t2_stream____value {
  display: flex;
}
.t2_stream____input {
  display: none;
}
.t2_stream____input:checked + .t2_stream____input-text {
  display: block;
}
/*
.t2_stream____input-text {
  display: none;
  width: 100%;
  margin: 0;
  padding: 5px 15px 5px 45px;
  background-color: #FF0000;
  border-radius: 20px;
  border: 2px solid red;
  color: #FFF;
}
 */
.t2_stream____input-text {
  display: none;
  margin: 0;
  padding: 0;
  border: none;
  color: #000;
  font-size: 0.95rem;
}
@media screen and (min-width: 600px) {
  .t2_stream____input-text{
    font-size: 0.8rem;
  }
}
.t2_stream____input-text:after{
  content: '( \25be  Filtern )';
  color: #ff2b06;
  font-size: 0.7rem;
  vertical-align: middle;
  margin-left: 10px;
}
.t2_stream____list {
  position: absolute;
  width: 100%;
  z-index: 12;
  max-width: 250px;
  padding: 0;
  list-style: none;
  opacity: 0;
  -webkit-animation-name: HideList;
  animation-name: HideList;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: step-start;
  animation-timing-function: step-start;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);
}
.t2_stream____option {
  display: block;
  padding: 15px;
  background-color: #fff;
}
.t2_stream____option:hover, .t2_stream____option:focus {
  color: #546c84;
  background-color: #fbfbfb;
}


@-webkit-keyframes HideList {
  from {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  to {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}

@keyframes HideList {
  from {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  to {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}
.t2_stream__taglist{
  list-style: none;
  margin: 0;
  text-align: left;
  padding: 10px 0;
  line-height: 1.7;
}
@media screen and (min-width: 600px) {
  .t2_stream__taglist{
    padding: 0;
    float: left;
    max-width: 300px;
  }
}
.t2_stream__tag{
  color: #646464;
  font-size: 14px;
  font-weight: lighter;
}
.t2_stream__tag a{
  color: #646464;
  text-decoration: none;
  padding: 1px 2px;
  transition: all ease-in-out 0.5s;
}

.t2_stream__tag a:hover{
  color: #FF0000;
}

.t2_stream__tag{
  display: inline-block;
  margin: 0 5px 0 0;
}

.t2_stream__footer{
  display: block;
  width: 100%;
  float: left;
}
.adv-interrupter{
  display: block;
  color: #000;
  font-weight: bold;
  margin: 0 0 10px 0;
}
.t2_ad{
  margin-bottom: 0;
}
.t2_stream__nl{
  width: 100%;
  display: block;
  margin: 10px 0;
  text-align: center;
}
.t2_stream__nl img{
  width: 100%;
  display: inline-block;
  max-width: 600px;
}
</style>
<style>
.autolink__koepfe {
  background-image: url('https://www.turi2.de/stuff/koepfe_autolink__emoticon_grey.png');
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: right;
  padding-right: 22px;
  padding-left: 2px;
}
.sharingicons{
  font-size: 14px;
  font-weight: lighter;
  width: auto;
  margin: 0;
}
@media screen and (min-width: 600px) {
  .sharingicons{
    float: right;
  }
}
.sharingicons__title{
  padding-right: 10px;
}
.t2_stream__filter,
.t2_stream__newsletter{
  color: #FF0000;
  font-size: .8rem;
  font-weight: bold;
}
.t2_stream__newsletter{
  padding-left: 10px;
}
.t2_stream__newsletter:before{
  content: '|';
  color: #000000;
  padding-right: 10px;
}
.t2_stream__newsletter--icon{
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 230.17 230.17'%3E%3Cpath d='M230 49.585c0-.263.181-.519.169-.779l-70.24 67.68 70.156 65.518c.041-.468-.085-.94-.085-1.418V49.585zm-80.793 77.316l-28.674 27.588a7.48 7.48 0 01-5.2 2.096 7.478 7.478 0 01-5.113-2.013l-28.596-26.647-70.614 68.064c1.717.617 3.56 1.096 5.49 1.096h197.667c2.866 0 5.554-.873 7.891-2.175l-72.851-68.009z'/%3E%3Cpath d='M115.251 138.757L222.447 35.496c-2.427-1.443-5.252-2.411-8.28-2.411H16.5c-3.943 0-7.556 1.531-10.37 3.866l109.121 101.806zM0 52.1v128.484c0 1.475.339 2.897.707 4.256l69.738-67.156L0 52.1z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  padding-left: 20px;
}
</style>
